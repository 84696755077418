<template>
  <h2 class="text-2xl flex items-center font-bold tracking-tight text-gray-900 px-8 py-4">
    Resources
  </h2>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
